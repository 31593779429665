import { refs } from './refs.js';

(() => {
  // const burgerEl = document.querySelector('.burger-menu');
  if (
    !window.location.pathname.includes('index.html') &&
    window.location.pathname !== '/'
  ) {
    sessionStorage.removeItem('activeEl');
  }

  if (refs.closeMenuBtn) {
    refs.closeMenuBtn.addEventListener('click', e => {
      const activeElHref = sessionStorage.getItem('activeEl');
      refs.navAll.forEach(navEl => {
        navEl.getAttribute('href') === activeElHref &&
          navEl.classList.add('active');
      });
      toggleModal();
    });
  }

  if (refs.headerMenu) {
    refs.headerMenu.addEventListener('click', toggleModal);
  }

  function toggleModal() {
    refs.headerMenu.classList.toggle('visually-hidden');
    refs.burgerEl.classList.toggle('burger-menu');
    refs.burgerEl.classList.toggle('burger-menu-open');
  }
})();
